import React, { FC } from "react"
import Container from "react-bootstrap/Container"

import { DEFAULT_PRODUCT_FAMILY_COLOR } from "gatsby-theme-nurofen/src/utils/constants"
import ProductCard from "gatsby-theme-nurofen/src/components/ProductCard"

import "gatsby-theme-nurofen/src/components/ProductCardList/ProductCardList.scss"
import { IPropsProductCardList } from "gatsby-theme-nurofen/src/components/ProductCardList/model"

const ProductCardList: FC<IPropsProductCardList> = ({
  shopBtnShow,
  products,
  CTABtn,
  isShowLabels,
  sorryMessage,
  isProductsCardListInViewport,
  isSmallDevice,
  isArticlesCounter = true,
  itemsTotal,
  countFilteredItems,
  counterFirstWord,
  counterMiddleWord,
}) => (
  <div data-test="ProductCardList" className="nf-product-list">
    <Container fluid>
      {isArticlesCounter ? (
        <p className="nf-product-list__counter">
          {`${counterFirstWord} ${countFilteredItems} ${counterMiddleWord} ${itemsTotal}`}
        </p>
      ) : null}
      {products?.length ? (
        <div className="nf-product-list-grid">
          {products.map(
            (
              {
                sku,
                preferred,
                productLinks,
                productFamily,
                defaultProductTitle,
                link,
                productImageAlt,
                pageName,
                defaultProductImage,
                productVariant,
              },
              productIndex
            ) => (
              <ProductCard
                key={sku}
                label={isShowLabels ? preferred?.[0]?.title : undefined}
                labelBgColor={
                  productFamily?.[0]?.productListingBadgeBgColor?.[0]
                    ?.properties?.colorPicker?.label ||
                  DEFAULT_PRODUCT_FAMILY_COLOR
                }
                title={pageName || defaultProductTitle}
                text=""
                img={defaultProductImage}
                classes="nf-product-list-card"
                btn={{
                  shopBtnAriaLabel:
                    productLinks?.[0]?.properties?.shopBtnAriaLabel,
                  link: [
                    {
                      url: productLinks?.[0]?.properties?.link?.[0]?.url,
                      name: productLinks?.[0]?.properties?.link?.[0]?.name,
                    },
                  ],
                  btnStyles: CTABtn?.btnStyles,
                  icon: productLinks?.[0]?.properties?.icon,
                  shopName: productLinks?.[0]?.properties?.shopName,
                }}
                shopBtnShow={shopBtnShow}
                link={link}
                fontColor="navy"
                showLabels
                alt={productImageAlt}
                isLazyLoading={
                  !(
                    isSmallDevice &&
                    isProductsCardListInViewport &&
                    productIndex === 0
                  )
                }
                sku={sku}
                preferred={preferred}
                productVariant={productVariant}
              />
            )
          )}
        </div>
      ) : (
        <h3 className="product-list-no-result">{sorryMessage}</h3>
      )}
    </Container>
  </div>
)

export default ProductCardList
